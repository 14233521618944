<template>
	<!-- 解决方案-客户管理-->
	<div>
		<GModule class="container" style="margin-bottom: 10px;">
			<ul class="money">
				<li>
					<p>上月合同额</p>
					<p>{{addData.lm}}</p>
				</li>
				<li>
					<p>本月合同额</p>
					<p>{{addData.m}}</p>
				</li>
				<li>
					<p>本季度合同额</p>
					<p>{{addData.q}}</p>
				</li>
				<li>
					<p>本年合同额</p>
					<p>{{addData.y}}</p>
				</li>
			</ul>
			<div class="condition-box">
				<div style="display: flex;">
					<div class="state-box">
						<p>客户</p>
						<a-input placeholder="请输入客户名称" style="width: 150px;" v-model:value="nickname"/>
					</div>
					<div class="state-box">
						<p>客户标签</p>
						<a-dropdown>
						    <template #overlay>
						        <a-menu selectable @click="handleMenuClick">
									<a-menu-item v-for="(nitem,nindex) in nstatusArr" :key="nindex">{{nitem.name}}</a-menu-item>
						        </a-menu>
						    </template>
						    <a-button>
								{{nstatusArr[nstatusIndex].name}}
						        <DownOutlined />
						    </a-button>
						</a-dropdown>
					</div>
					<div class="state-box">
						<p style="width: 60px;">创建时间</p>
						<a-range-picker
						    style="width: 400px"
						    :ranges="ranges"
						    show-time
						    format="YYYY-MM-DD HH:mm:ss"
							@change="change"/>
					</div>
				</div>
				<a-button class="btn clo3" @click="search()">查询</a-button>
			</div>
			<a-table
				class="ttable" 
				:columns="columnsHe" 
				:data-source="data" 
				:scroll="{ x: 500}" 
				bordered
				:pagination="false">
				<template #bodyCell="{ column ,record}">
					<template v-if="column.dataIndex === 'nickname'">
						{{record.sale.nickname}}
					</template>
					<template v-if="column.dataIndex === 'labels_arr'">
						<span v-for="(itm,ind) in record.sale.labels_arr" :key="ind">{{itm.name}}&nbsp;</span>
					</template>
					<template v-if="column.dataIndex === 'linkman'">
						{{record.sale.linkman}}
					</template>
					<template v-if="column.dataIndex === 'contact'">
						{{record.sale.contact}}
					</template>
					<template v-if="column.dataIndex === 'remark'">
						{{record.sale.remark}}
					</template>
					<template v-if="column.key === 'operation'">
						<div class="caozuo">
							<p @click="open(record.id,record.project_name)">收款</p>
							<p @click="openAdd(record.id,record.project_name,record.add_amount)">追加</p>
							<p @click="openInvoice(record.id,record.project_name,record.invoiced_amount)">发票</p>
							<p @click="detailBtn(record.id)">详情</p>
							<p @click="updateBtn(record.id)">编辑</p>
						</div>
					</template>
				</template>
			</a-table>
			<div class="documentFt" style="margin-top: 10px;">
				<a-pagination size="small" :pageSize="limit" :total="total" :current="page" @change="pageChange"/>
			</div>
		</GModule>		
	</div>
</template>

<script>
	import dayjs from 'dayjs';
	export default{
		data(){
			return{
				nstatusArr:[
					{id: 0, name: "全部"},
				] , // 客户标签
				nstatusIndex:0, // 客户标签下标
				columnsHe: [{
				    title: '序号',
				    width: 70,
				    dataIndex: 'id',
				    fixed: 'left'
				  }, {
				    title: '项目名称',
				    width: 300,
				    dataIndex: 'project_name'
				  }, {
				    title: '客户名称',
				    width: 200,
				    dataIndex: 'nickname'
				  }, {
				    title: '客户标签',
				    width: 120,
				    dataIndex: 'labels_arr'
				  }, {
				    title: '联系人',
				    width: 100,
				    dataIndex: 'linkman'
				  }, {
				    title: '联系方式',
				    width: 120,
				    dataIndex: 'contact'
				  }, {
				    title: '签约时间',
				    width: 120,
				    dataIndex: 'createtime_text'
				  }, {
				    title: '合同编号',
				    width: 120,
				    dataIndex: 'contract_no'
				  }, {
				    title: '合同金额',
				    width: 120,
				    dataIndex: 'contract_money'
				  }, {
				    title: '追加额',
				    width: 120,
				    dataIndex: 'add_amount'
				  }, {
				    title: '总金额',
				    width: 120,
				    dataIndex: 'total_amount'
				  }, {
				    title: '已收款金额',
				    width: 120,
				    dataIndex: 'received_amount'
				  }, {
				    title: '待收款金额',
				    width: 120,
				    dataIndex: 'pending_amount'
				  }, {
				    title: '已开发票额',
				    width: 120,
				    dataIndex: 'invoiced_amount'
				  }, {
				    title: '备注',
				    width: 120,
				    dataIndex: 'remark'
				  },{
					title: '操作',
					key: 'operation',
					fixed: 'right',
					width: 180,
				}],
				data: [],
				limit:10,
				page:1,
				nickname:'',// 客户名称
				createtime:'' ,// 创建时间
				ranges: {
					'今天': [dayjs().startOf('day'), dayjs().endOf('day')],
					'本周': [dayjs().startOf('week'), dayjs().endOf('week')],
					'本月': [dayjs().startOf('month'), dayjs().endOf('month')],
					'一个月前': [dayjs().subtract(1, 'month'),dayjs().endOf('day')],
				},
				total:0 ,// 总条数
				addData:{} // 新增数
			}
		},
		computed:{
			isFresh(){
				return this.$store.state.isFresh
			},
			isUpdateFresh(){
				return this.$store.state.isUpdateFresh
			},
			collectInfo(){
				return this.$store.state.collectInfo
			},
			isAddCollectFresh(){
				return this.$store.state.isAddCollectFresh
			},
			updateId(){
				return this.$store.state.updateId
			},
			isCollectUpdateFresh(){
				return this.$store.state.isCollectUpdateFresh
			},
			proId(){
				return this.$store.state.proId  
			},
		},
		watch:{
			proId(val,oldvalue){
				if(val){
					// 重置数据，刷新列表
					this.page = 1 
					this.nickname = ''
					this.createtime = ''
					this.nstatusIndex = 0
					this.getDetail()
				}
			},
			isFresh(val,oldvalue){
				// 添加后刷新数据
				if(val){
					// 重置数据，刷新列表
					this.page = 1 
					this.nickname = ''
					this.createtime = ''
					this.nstatusIndex = 0
					this.$store.commit('setIsFresh',0)
					this.getList()
				}
			},
			isAddCollectFresh(val,oldvalue){
				// 回显追加金额
				if(val){
					this.getInfo(this.$store.state.collectInfo.id,1)
				}
			},
			isUpdateFresh(val,oldvalue){
				// 回显发票金额
				if(val){
					this.getInfo(this.$store.state.collectInfo.id,2)
				}
			},
			isCollectUpdateFresh(val,oldvalue){
				// 编辑后刷新
				if(val){
					this.getInfo(val,3)
				}
			}
		},
		async created(){
			this.getDetail()
		},
		methods:{
			async getDetail(){
				// 获取客户标签
				let res = await this.$utils.api.get({
					url:'/sale/label_selectpage',
					result:1
				})
				if(res.code == 1){
					this.nstatusArr = this.nstatusArr.concat(res.data.list) // 拼接
				}
				this.getList()
			},
			handleMenuClick(e){
				// 选择可标签
				this.nstatusIndex = e.key
			},
			change(date,value){
				// 选择创建时间
				if(value[0] == '' || value[1] == ''){
					// 创建时间要同时满足开始时间和结束时间才可以查询
					this.createtime = ''
					return
				}
				let val = value.join(' - ') // 拼接日期
				this.createtime = val
			},
			pageChange(page,pageSize){
				// 切换页码时查询列表
				this.page = page
				this.getList()
			},
			search(){
				// 根据条件查询列表
				this.page = 1 //每次点击查询按钮都要重置页码为1
				this.getList()
			},
			async getList(){
				// 请求客户管理列表
				let info = {
					limit:this.limit,
					page:this.page,
					label_id:this.nstatusArr[this.nstatusIndex].id,
					op:{},
					filter:{}
				}
				if(this.nickname){
					info.op['sale.nickname'] = 'LIKE'
					info.filter['sale.nickname'] = this.nickname
				}
				if(this.createtime){
					info.op['createtime'] = 'RANGE'
					info.filter['createtime'] = this.createtime
				}
				let res = await this.$utils.api.post({
					url:'/contract/p_index',
					data:info,
					result:1
				})
				if(res.code == 1){
					this.data = res.data.rows
					this.total = res.data.total
					this.addData = res.data.extend
				}
			},
			detailBtn(id){
				this.$router.push({path:'/solutionCustomerDetail', query:{ids:id}});
			},
			open(id){
				// 弹出收款框
				this.$store.commit('setUpdateId',id)
				this.$store.commit('setIsCollection',1)
			},
			openAdd(id,name,money){
				// 弹出追加框
				this.$store.commit('setCollectInfo',{id:id,name:name,money:money})
				this.$store.commit('setIsAddCollect',1)
			},
			openInvoice(id,name,money){
				// 弹出发票框
				this.$store.commit('setCollectInfo',{id:id,name:name,money:money})
				this.$store.commit('setIsInvoice',1)
			},
			async getInfo(id,type){
				// 获取详情
				let res = await this.$utils.api.post({
					url:'/contract/getRow',
					data:{
						ids:id
					},
					result:1
				})
				if(res.code == 1){
					for(let i in this.data){
						if(this.data[i].id == res.data.id){
							if(type == 1){
								// 追加金额回显
								this.data[i].add_amount = res.data.add_amount
								// 重置
								this.$store.commit('setCollectInfo',{})
								this.$store.commit('setIsAddCollectFresh',0) 
							}
							if(type == 2){
								// 发票金额回显
								this.data[i].invoiced_amount = res.data.invoiced_amount
								// 重置
								this.$store.commit('setCollectInfo',{})
								this.$store.commit('setIsUpdateFresh',0) 
							}
							if(type == 3){
								// 编辑后更新数据
								this.data[i].sale.nickname = res.data.sale.nickname
								this.data[i].sale.linkman = res.data.sale.linkman
								this.data[i].sale.contact = res.data.sale.contact
								this.data[i].sale.remark = res.data.sale.remark
								this.data[i].sale.labels_arr = res.data.sale.labels_arr
								// 重置
								this.$store.commit('setIsCollectUpdateFresh',0) 
							}
							break
						}
					}
				}
			},
			updateBtn(id){
				this.$store.commit('setUpdateId',id) //列表id
				// 弹出编辑框
				this.$store.commit('setIsCollectUpdate',1)
				
			}
		}
	}
</script>

<style scoped lang="scss">
	.container{
		margin: 20px;
		.state-box{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 5px;
		}
		.time-box{
			display: flex;
			align-items: center;
			p{
				&:nth-child(1){
					margin-right: 15px;
				}
				&:nth-child(2){
					width: 80px;
					font-size: 12px;
					color: #999999;
					border: 1px solid #ddd;
					border-radius: 5px;
					padding: 5px 10px;
				}
			}
		}
		.money{
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			background-color: #f5f5f5;
			margin-bottom: 15px;
			li{
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				flex: 1;
				padding: 20px 0;
				p{
					margin: 0;
					padding: 0;
					&:nth-child(1){
						margin-bottom: 10px;
					}
				}
			}
		}
		.condition-box{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 5px 0 10px 0;
			.state-box{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 5px;
				p{
					margin: 0 10px 0 20px;
				}
			}
			.btn{
				margin-left: 20px;
				margin-bottom: 5px;
			}
		}
		.caozuo{
			display: flex;
			justify-content: space-around;
			align-items: center;
			p{
				margin: 0;
				font-size: 12px;
				color: #407cff;
			}
		}
	}
</style>